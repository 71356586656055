<template>
    <div class="trial-section pt-min-100 pb-100 bg-main page-bg page-bg-1">
        <div class="container">
            <div class="section-title section-title-lg section-title-white mb-0">
                <h2>Join us in public beta testing!</h2>
                <p>Be the first to experience the power of our AI landing page service and start creating customized landing pages that will drive conversions and grow your business.</p>
              <a href="https://t.me/Lndrbot?start=lndr-site"  target="_blank" class="btn main-btn main-btn-white" style="font-size: 1.2rem;">Try in Telegram&nbsp;&nbsp;<i class="icofont-telegram" style="font-size: 2rem;"></i></a>
<!--                <router-link to="/contact" class="btn main-btn main-btn-white main-btn-rounded-icon">-->
<!--                    Early Access -->
<!--                    <span><i class="icofont-rounded-right"></i></span>-->
<!--                </router-link>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FreeTrial'
}
</script>