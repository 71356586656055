<template>
    <div class="choose-section pt-100 pb-70 bg-off-white" id="why_us">
        <div class="container">
            <div class="section-title">
                <h2>Our features</h2>
                <p>Our advanced AI tool creates landing pages that convert and align with customers goals, while saving valuable time and resources.</p>
<!--                <p>Advanced AI technology and customized solutions help our clients create landing pages that convert and align with their brand's goals, while saving valuable time and resources.</p>-->
            </div>
            <div class="process-content">
                <div class="row justify-content-center">
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-four.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Advanced AI Technology</h3>
                                <p>Our service uses advanced AI technology, including ChatGPT and Midjourney, to create landing pages that are optimized for conversion and aligned with your brand's goals.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-five.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Customized Solutions</h3>
                                <p>We work closely with our clients to understand their unique needs and create customized landing pages that are tailored to their specific business goals and brand message.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-six.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Time and Cost Savings</h3>
                                <p>Our service saves our clients valuable time and resources that would have been spent on creating landing pages from scratch, allowing them to focus on growing their business and increasing revenue.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>