<template>
    <div class="service-section pt-100 pb-70" id="for_whom">
        <div class="container">
            <div class="section-title section-title-lg">
                
                <h2>AI transformation of your business!</h2>
                <p>We offer a universal solution for business owners, marketers,
                    designers and other users who need a high-quality landing page with minimal time and resources.</p>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-automation"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Small businesses and startups</h3>
                            <p>Small businesses and startups who want to quickly launch a landing page for their product or service but don't have enough resources or time.</p>
                            <!-- <router-link to="/single-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-predictive-chart"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Digital marketers</h3>
                            <p>Digital marketers who work with clients and need a tool that allows them to quickly create quality landing pages for their campaigns.</p>
                            <!-- <router-link to="/single-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-deep-learning"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Copywriters and designers</h3>
                            <p>Freelance copywriters and designers who can use this service to quickly create persuasive pages for their clients.</p>
                            <!-- <router-link to="/single-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-data-mining"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Students</h3>
                            <p>Students and learners in the field of marketing and design who can use this service for practical study of creating landing pages and improving their skills.</p>
                            <!-- <router-link to="/single-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-machine-learning-2"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Internet entrepreneurs</h3>
                            <p>Internet entrepreneurs who are launching a new product or service and want to quickly test the effectiveness of their idea through A/B testing.</p>
                            <!-- <router-link to="/single-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-diagram"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Large companies</h3>
                            <p>Large companies that have a large volume of landing pages and want to automate the process of creating new pages with an automatic generation service.</p>
                            <!-- <router-link to="/single-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>