<template>
    <div class="newsletter-section pt-min-100 pb-100 page-bg page-bg-2 page-bg-overlay">
        <div class="container position-relative">
            <div class="section-title section-title-white">
                <h2>Subscribe For Newsletter</h2>
                <p>Subscribe To Our Newsletter & Stay Updated</p>
            </div>
            <div v-if="error" class="alert alert-warning newsletter-form">{{ error }}</div>
            <div v-if="is_success" class="newsletter-form text-white text-center"><h1><i class="icofont-tick-boxed text-white text-bg-success"></i></h1><h3 class="text-white">Lander will notify you about new great features!</h3></div>
            <form v-else
                class="newsletter-form"
                  @submit="submit($event)">
                <div class="form-group m-0 newsletter-form">
                    <input type="text" name="email" id="email" v-model="email" :disabled="loading" class="form-control" placeholder="Enter Email*">
                    <button class="btn main-btn" type="submit" :disabled="loading">
                        <span v-if="loading" class="spinner-border spinner-border-sm text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </span>
                        <span class="ms-1">Subscribe</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'Newsletter',
    data(){
        return {
            email: null,
            is_success: false,
            error: false,
            loading: false,
        }
    },
    methods: {
        submit(e) {
            e.preventDefault();

            this.loading = true;

            const grecaptchaCallback = function (token) {
                const data = new FormData();
                data.append('t', token);
                data.append('email', this.email);

                axios.post(
                    'https://creator.lndr.pro/?route=sitegen/api/info_subscription',
                    data,
                    {headers: {"X-No-Cookie": 1}}
                ).then((response) => {
                    if (response.data.success) {
                        this.error = false;
                        this.is_success = true;
                    } else {
                        this.error = response.data.error;
                    }

                    this.loading = false;
                });
            }.bind(this)

            window.grecaptcha.ready(function() {
                window.grecaptcha.execute('6LdZ5M0lAAAAANoG-1nmpRbEKFtVa-qBcqH2Z81X', {action: 'submit'})
                    .then(grecaptchaCallback);
            });
        }
    }
}
</script>