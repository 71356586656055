<template>
    <div>
        <Navbar class="bg-white" />
<!--        <PageTitle pageTitle="Early Access" />-->
        <!-- <ContactInfo /> -->
        <ContactForm />
        <Newsletter />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
// import PageTitle from '../Common/PageTitle'

import ContactForm from '../Contact/ContactForm'
import Newsletter from '../Common/Newsletter'
import Footer from '../Layout/Footer'

export default {
    name: 'ContactPage',
    components: {
        Navbar,
        // PageTitle,
      
        ContactForm,
        Newsletter,
        Footer,
    }
}
</script>