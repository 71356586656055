<template>
    <div class="about-section bg-off-white pt-100 pb-70" id="about_us">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-image text-center">
                        <img src="../../assets/images/qr-lndr-bot.svg" style="width: 23rem">
                    </div>
                </div>
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-details">
                        <div class="section-title section-title-left text-start">
                            
                            <h2>Some Reasons Why We're the Best Choice for Your Business</h2>
                        </div>
                        <div class="about-content">
                            <p>We use the most advanced technologies such as ChatGPT and Midjourney to 
                                create landing pages that stand out from competitors. Our service allows 
                                you to create a unique design for your landing pages in just a few minutes, saving you time and effort. </p>
                            <ul>
                                <li>Innovative Approach</li>
                                <li>Convenience</li>
                                <li>Persuasive Texts</li>
                                <li>Cost Savings</li>
                            </ul>
                            <p> Our AI algorithms create persuasive texts that capture the attention of potential customers and increase conversion rates. Our service significantly saves money on hiring copywriters and designers, allowing our clients to obtain high-quality landing pages at an affordable price.</p>
                            <!-- <router-link to="/about-us" class="btn main-btn">About Us</router-link> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>