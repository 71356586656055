<template>
    <footer class="bg-off-white footer">
        <div class="footer-shapes">
            <div class="footer-shape">
                <img src="../../assets/images/shape-1.png" alt="shape">
            </div>
            <div class="footer-shape footer-round-shape">
                <img src="../../assets/images/shape-2.png" alt="shape">
            </div>
        </div>
        <div class="footer-upper pt-100 pb-80 position-relative">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="footer-content-item">
                            <div class="footer-logo">
                                <router-link to="/">
                                    <img src="../../assets/images/32.png" alt="logo">
                                </router-link>
                            </div>
                            <!-- div class="footer-details">
                                <p>Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, 
                                    nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate.</p>
                                <ul class="social-list social-list-btn">
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i class="icofont-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank">
                                            <i class="icofont-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="icofont-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.pinterest.com/" target="_blank">
                                            <i class="icofont-pinterest"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div -->
                        </div>
                    </div>
                    <!-- <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title">
                                <h3>Useful Links</h3>
                            </div>
                            <ul class="footer-details footer-list">
                                <li><router-link to="/about-us">About Us</router-link></li>
                                <li><router-link to="/blogs">News & Blogs</router-link></li>
                                <li><router-link to="/services">Services</router-link></li>
                                <li><router-link to="/products">Products</router-link></li>
                                <li><router-link to="/pricing">Our Pricing</router-link></li>
                                <li><router-link to="/contact">Contact</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title">
                                <h3>Services</h3>
                            </div>
                            <ul class="footer-details footer-list">
                                <li><router-link to="/single-services">Robotic Automation</router-link></li>
                                <li><router-link to="/single-services">Predictive Analytic</router-link></li>
                                <li><router-link to="/single-services">Deep Learning</router-link></li>
                                <li><router-link to="/single-services">Statistic Modeling</router-link></li>
                                <li><router-link to="/single-services">Data Mining</router-link></li>
                                <li><router-link to="/single-services">Security & Surveillance</router-link></li>
                            </ul>
                        </div>
                    </div> -->
                    <div class="col-sm-6 col-md-6 col-lg-4">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title ms-3">
                                <h3>Contacts</h3>
                            </div>
                            <div class="footer-details footer-address">
                                <div class="footer-address-item">
                                    <div class="footer-address-text">
<!--                                        <h4>Telegram Bot:</h4>-->
                                        <p><i class="icofont-telegram" style="font-size: 2rem;margin-right: 0.2em"></i><a href="https://t.me/Lndrbot?start=lndr-site">@Lndrbot</a> - the AI generator!</p>
                                    </div>
                                </div>
                              <div class="footer-address-item">
                                <div class="footer-address-text">
                                  <p><i class="icofont-support" style="font-size: 2rem;margin-right: 0.2em"></i><a href="https://t.me/Landersupport">@Landersupport</a> - Help is here!</p>
                                </div>
                              </div>
                                <div class="footer-address-item">
                                    <div class="footer-address-text">
                                        <p><i class="icofont-ui-email" style="font-size: 2rem;margin-right: 0.2em"></i><a href="mailto:care@lndr.pro">care@lndr.pro</a> - Suggest and cooperate!</p>
                                    </div>
                                </div>
                                <!-- div class="footer-address-item">
                                    <div class="footer-address-text">
                                        <h4>Address:</h4>
                                        <p></p>
                                    </div>
                                </div -->
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-5">
                        <span class="float-lg-end">
                            <img class="ms-3" src="https://www.positivessl.com/images/seals/positivessl_trust_seal_lg_222x54.png">
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-lower bg-blue position-relative">
            <div class="container">
                <div class="footer-copyright-text footer-copyright-text-white">
                    <p>Copyright ©{{currentYear}} Lndr.pro. </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>
