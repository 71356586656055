<template>
    <div class="contact-form-section pb-100 pt-100">
        <div class="container">
            <div class="contact-form-box">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="contact-map contact-form-item">
                            <img src="../../assets/images/early.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="contact-form-item desk-pad-left-40">
                            <div class="section-title text-start section-title-left">
                                <h2>Early Access!</h2>
                                <p>Early access users will have the chance to explore our features and provide valuable feedback that will help us improve the service. Additionally, early access users will receive exclusive offers and promotions that will not be available to later users. By signing up for early access, users can stay ahead of the competition and gain a competitive edge in their business.</p>
                            </div>
                            <div v-if="error" class="alert alert-warning">{{ error }}</div>
                            <div v-if="early_access_success" class="text-center"><h1><i class="icofont-tick-boxed text-white text-bg-success"></i></h1><h4>Lander will contact you soon!</h4></div>
                            <form v-else
                                  class="contact-form"
                                  action=""
                                  @submit="submit($event)"
                                  method="post">
                                <div class="form-group mb-20">
                                    <input type="text" name="email" id="email" v-model="email" :disabled="loading" class="form-control" placeholder="Your Email*" />
                                </div>

                                <div class="form-group mb-20">
                                    <input type="text" name="subject" id="subject" v-model="company" :disabled="loading" class="form-control" placeholder="Your Company*" />
                                </div>

<!--                                <div class="input-checkbox mb-20">-->
<!--                                    <input type="checkbox" id="contact1" v-model="agreed">-->
<!--                                    <label for="contact1">-->
<!--                                        Accept -->
<!--                                        <router-link to="/terms-of-service">Terms of Service</router-link> -->
<!--                                        and -->
<!--                                        <router-link to="/privacy-policy">Privacy Policy</router-link>-->
<!--                                    </label>-->
<!--                                </div>-->
                                <div class="section-title text-start section-title-left">

                                    This site is protected by reCAPTCHA and the <nobr><a target="_blank" href="https://policies.google.com/privacy" data-has-link="true" rel="noopener">Google Privacy Policy</a></nobr>
                                    and <nobr><a target="_blank" href="https://policies.google.com/terms" data-has-link="true" rel="noopener">Terms of Service</a></nobr> apply.

                                </div>
                                <div class="form-button">
                                    <button class="btn main-btn full-width" type="submit" :disabled="loading">
                                        <span v-if="loading" class="spinner-border spinner-border-sm text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </span>
                                        <span class="ms-1">Early Access</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'ContactForm',
    data(){
        return {
            email: null,
            company: null,
            early_access_success: false,
            error: false,
            agreed: true,
            loading: false,
        }
    },
    methods: {
        submit(e) {
            e.preventDefault();

            this.loading = true;

            const grecaptchaCallback = function (token) {
                const data = new FormData();
                data.append('t', token);
                data.append('email', this.email);
                data.append('company', this.company);

                axios.post(
                    'https://creator.lndr.pro/?route=sitegen/api/request_early_access',
                    data,
                    {headers: {"X-No-Cookie": 1}}
                ).then((response) => {
                    if (response.data.success) {
                        this.error = false;
                        this.early_access_success = true;
                    } else {
                        this.error = response.data.error;
                    }

                    this.loading = false;
                });
            }.bind(this)

            window.grecaptcha.ready(function() {
                window.grecaptcha.execute('6LdZ5M0lAAAAANoG-1nmpRbEKFtVa-qBcqH2Z81X', {action: 'submit'})
                    .then(grecaptchaCallback);
            });
        }
    }
}
</script>