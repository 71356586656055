<template>
    <div class="feature-section pb-70 section-to-header">
        <div class="container">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-green">
                                    <i class="flaticon-machine-learning-1"></i>
                                </div>
                                <span>Unique</span>
                            </div>
                            <div class="feature-card-body">
                                <p>With our service, you can create a unique design for your landing page quickly and effortlessly.</p>
                                <!-- <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-yellow">
                                    <i class="flaticon-voice-recognition"></i>
                                </div>
                                <span>Effortless</span>
                            </div>
                            <div class="feature-card-body">
                                <p>Our service allows you to create ready-to-use landing pages in several clicks</p>
                                <!-- <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-blue">
                                    <i class="flaticon-machine-learning"></i>
                                </div>
                                <span>Appealing</span>
                            </div>
                            <div class="feature-card-body">
                                <p>Lndr.pro algorithms automatically optimize the text, making it more appealing to visitors.</p>
                                <!-- <router-link to="/single-services" class="redirect-link">
                                    Discover More
                                    <i class="icofont-rounded-right"></i>
                                </router-link> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-violet">
                                    <i class="flaticon-processor"></i>
                                </div>
                                <span>Efficient</span>
                            </div>
                            <div class="feature-card-body">
                                <p>Our service saves time and money by eliminating the need to hire copywriters and designers.</p>
                                <!-- <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feature'
}
</script>