<template>
    <div class="header header-bg header-bottom-space header-bg-image">
        <div class="container-fluid p-lg-0 custom-container-fluid">
            <div class="row m-lg-0 align-items-center">
                <div class="col-lg-6 p-lg-0">
                    <div class="max-585 ms-lg-auto">
                        <div class="header-content header-content-white m-0">
                            <h1>Generate unique and selling landing pages with AI!</h1>
                            <p>No more expenses on designers and copywriters. Increase the efficiency of your business with ChatGPT and Midjourney!</p>
                            <div class="button-group button-group-animated">
                              <a href="https://t.me/Lndrbot?start=lndr-site"  target="_blank" class="btn main-btn main-btn-white" style="font-size: 1.2rem;">Try in Telegram&nbsp;&nbsp;<i class="icofont-telegram" style="font-size: 2rem;"></i></a>

<!--                                <router-link to="/contact" class="btn main-btn main-btn-white">-->
<!--                                    Early Access-->
<!--                                </router-link>-->
                            </div>
                        </div>

                    </div>

                </div>
              <div class="col-lg-6 header-gif-wrapper">
                <iframe src="https://www.youtube-nocookie.com/embed/rrKhzaP8KqA?si=dzRrqCSZvasPYEpV&autoplay=1&mute=1&controls=0&showinfo=0&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>

            </div>



        </div>

    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>
